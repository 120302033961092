
import React from "react"
import { Helmet } from "react-helmet"
import Search from "../components/search"
import Layout from "../components/layout"
import Ad from "../components/ad"

import { graphql } from "gatsby"
import _ from "lodash"



export default ({data}) => {
    
    const searchExamples = _.shuffle(data.searchExamples.nodes
        .filter(page => page.attributes.length <= 2))
      .slice(0,7)
      

    return (
      <Layout style={{margin: "0px", padding: "0px"}}>
        <Helmet>
            <title>Suche</title>
            <meta name="keywords" 
                content="vornamensuche, suche"/>
            <link rel="canonical" href='/'/>
        </Helmet>        
        <Search searchExamples={searchExamples}/>

        <Ad id="adnz_maxiboard_1" />
      </Layout>
    )
  }

  export const query = graphql`
  query {
    searchExamples : allPagesJson (sort: {fields: number_of_names, order: DESC}, limit: 100) {
      nodes {
        attributes {
          imagePath
          id
        }
        number_of_names
        title
        url
      }
    }
  }`